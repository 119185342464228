import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Capacitaciones from "../pages/Capacitaciones";
import Contactanos from "../pages/Contactanos";
import Historia from "../pages/Historia";
import Home from "../pages/Home";
import Noticias from "../pages/noticias/Noticias";
import QuienesSomos from "../pages/QuienesSomos";
import Voluntarios from "../pages/Voluntarios";
import RendicionCuentas from "../pages/RendicionCuentas";
import Simbolos from "../pages/Simbolos";
import Sugerencias from "../pages/Sugerencias";
import Terminos from "../pages/Terminos";
import Valores from "../pages/Valores";
import BomberosJr from "../pages/BomberosJr";
import Transparencia from "../pages/Transparencia";
import Brigadistas from "../pages/Brigadistas";
import BaseLegal from "../pages/BaseLegal";
import DetalleNoticia from "../pages/noticias/DetalleNoticias";
import RepositorioDigital from "../pages/RepositorioDigital";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/transparencia" element={<Transparencia />} />
        <Route path="/rendicion_cuentas" element={<RendicionCuentas />} />
        <Route path="/base_legal" element={<BaseLegal />} />
        <Route path="/repositorio_digital" element={<RepositorioDigital />} />
        <Route path="/contactanos" element={<Contactanos />} />
        <Route path="/capacitaciones" element={<Capacitaciones />} />
        <Route path="/valores" element={<Valores />} />
        <Route path="/quienes-somos" element={<QuienesSomos />} />
        <Route path="/simbolos" element={<Simbolos />} />
        <Route path="/registro-voluntarios" element={<Voluntarios />} />
        <Route path="/registro-bomberosjr" element={<BomberosJr />} />
        <Route path="/registro-brigadistas" element={<Brigadistas />} />
        <Route path="/sugerencias" element={<Sugerencias />} />
        <Route path="/historia" element={<Historia />} />
        <Route path="/terminos" element={<Terminos />} />
        {/* <Route path="/noticias/:categoria" element={<Noticias />} /> */}
        <Route path="/noticias/:id" element={<DetalleNoticia />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path='*' component={<Home />} />
      </Routes>
    </Router>
  );
};
export default Routing;
