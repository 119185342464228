import React, { useEffect, useState } from "react";
import CardPeriodo from "../components/CardPeriodo";
import Layout from "../layout/Layout";
import { faCaretLeft, faMinus, faPlus, faPlusCircle, faPlusSquare, faSpinner, faSquarePlus, faTimesSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionBody, AccordionHeader, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";

const initialState = {
  loading: false,
  // anios: [2021, 2022, 2023, 2024, 2025],
  anios: Array.from({ length: (new Date().getFullYear()) - 2021 + 1 }, (_, i) => 2021 + i),
  anioActual: null,
  months: [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ],
  rutaStorage: "https://bp.bomberosmanta.gob.ec/storage/transparency/"
}

const Transparencia = () => {

  const [state, setState] = useState(initialState);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const [docs, setDocs] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [showModalExcel, setShowModalExcel] = useState(false);
  const [contentModal, setContentModal] = useState('');

  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (id) => {
      setOpenItems((prev) =>
          prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
      );
  };

  const readDatabase = async () => {
    setState({ ...state, loading: true });
    const url = "https://bp.bomberosmanta.gob.ec/api/getTransparencia";
    // const url = "http://127.0.0.1:8001/api/getTransparencia";
    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      }
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      setState({ ...state, loading: false });
      const filter = content.categorias.filter(x => (x.valor !== 'Rendición de Cuentas' && x.valor !== 'Base Legal' && x.valor !== 'Repositorio Digital'))
      setCategorias(filter);
      setDocs(content.registros);
    } else {
      setCategorias([]);
      setDocs([]);
      setState({ ...state, loading: false });
    }
  }


  const visualizarPeriodo = async (anio) => {
    setState({
      ...state, anioActual: {
        anio: anio,
        documentos: docs[anio]
      }
    });
  }

  const resetearPeriodo = async () => {
    setState({ ...state, anioActual: null })
  }

  useEffect(() => {
    readDatabase()
  }, [])


  const anioItems = state.anios.map((anio, index) =>
    <div className="col-span-1" key={index} onClick={() => visualizarPeriodo(anio)}>
      <CardPeriodo title={"Período " + anio} />
    </div>
  );

  const mesesItem = (categoria) => {
    if (categoria.map !== null) {
      return state.months.map((month, index) =>
        <div key={index}>
          <div>
            <Accordion open={open === (index)}>
              <AccordionHeader onClick={() => handleOpen((index))} className="px-1">
                {month}
              </AccordionHeader>
              <AccordionBody className="px-5">
                {state.anioActual !== null && state.anioActual.documentos !== undefined ?
                  (
                    state.anioActual.documentos[categoria.valor] !== undefined && state.anioActual.documentos[categoria.valor][index + 1] !== undefined ?
                      state.anioActual.documentos[categoria.valor][index + 1].map((doc, index_y) =>
                        <div key={index_y}>
                          {
                            doc.type_file === 'IMAGE' ?
                            showContentImage(doc)
                              : (
                                doc.type_file === 'VIDEO' ?
                                showContentVideo(doc)
                                  : (
                                    doc.type_file === 'PDF' ?
                                    showContentEnlace(doc,'PDF')
                                      : (
                                        doc.type_file === 'ENLACE' ?
                                        showContentEnlace(doc,'ENLACE')
                                          :
                                          (
                                            doc.type_file === 'EXCEL' ?
                                            showContentExcel(doc,(index +'_'+ index_y))
                                              : ''
                                          )
                                      )

                                  )
                              )
                          }
                        </div>
                      )
                      :
                      showContentNoRegistros()
                  )
                  :
                  showContentNoRegistros()
                }
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      );
    } else {
      if (state.anioActual.documentos !== undefined && state.anioActual.documentos[categoria.valor] !== undefined) {
        return state.anioActual.documentos[categoria.valor].map((doc, index) =>
          <div key={index}>
            {
              doc.type_file === 'IMAGE' ?
              showContentImage(doc)
                : (
                  doc.type_file === 'VIDEO' ?
                  showContentVideo(doc)
                    : (
                      doc.type_file === 'PDF' ?
                      showContentEnlace(doc,'PDF')
                        : (
                          doc.type_file === 'ENLACE' ?
                          showContentEnlace(doc,'ENLACE')
                            :
                            (
                              doc.type_file === 'EXCEL' ?
                              showContentExcel(doc,(index))
                                : ''
                            )
                        )

                    )
                )
            }
          </div>
        );
      } else {
        showContentNoRegistros()
      }
    }
  }

  const detalleAnios = () => {
    return (
      <div>
        <div className="flex w-full bg-slate-400 rounded-md py-2">
          <div className="flex-auto w-14 px-2">
            <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline" onClick={resetearPeriodo}>
              <FontAwesomeIcon icon={faCaretLeft} className="mr-1" />Volver
            </button>
          </div>
          <div className="flex-auto w-80 text-center">
            <h1 className="text-xl font-bold text-white">Período {state.anioActual.anio}</h1>
          </div>
          <div className="flex-auto w-14 text-right px-2">
          </div>
        </div>
        <div className="border mt-2 rounded p-2">
          <Tabs value={categorias[0].valor}>
            <TabsHeader className="bg-zinc-200 py-2 px-5">
              {categorias.map((categoria, index) => (
                <Tab key={index} value={categoria.valor}>
                  <span className="text-zinc-600 text-base">{categoria.valor}</span>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody animate={{
              initial: { y: 250 },
              mount: { y: 0 },
              unmount: { y: 250 },
            }}>
              {categorias.map((categoria, index) => (
                <TabPanel key={index} value={categoria.valor}>
                  {mesesItem(categoria)}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
    );

  }

  const closeModalExcel = () => {
    setShowModalExcel(false);
  }

  const updateModalExcel = (file) => {
    const urlVisor = 'https://view.officeapps.live.com/op/embed.aspx?src=';
    setContentModal(urlVisor + state.rutaStorage + file);

    setShowModalExcel(true)
  }

  const ModalExcel = () => {
    return (
      <>
        {showModalExcel ? (
          <>
            <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center px-10">

                  <div className="relative transform overflow-hidden rounded text-left shadow-xl transition-all sm:my-8 w-full">
                    <div className="flex flex-col">
                      <div className="flex justify-end w-full float-right">
                        <FontAwesomeIcon icon={faTimesSquare} className="m-0 text-white" onClick={closeModalExcel} style={{ 'cursor': 'pointer', 'fontSize': '20pt' }} />
                      </div>
                      <div className="sm:flex sm:items-start">
                        <iframe src={contentModal} title="Modal Comprobante" width="100%" height="600px"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  /* Funciones para mostrar contenido */
  const showContentImage = (doc) => {
    return ( 
    <div className="flex justify-center">
      <div className="rounded-lg shadow p-2 border" style={{ width: '70%' }}>
        <span className="capitalize font-semibold ml-1 text-gray-500 italic">{doc.title}</span>
        <hr className="mb-2" />
        <img src={state.rutaStorage + doc.file} alt="Imagen" className="rounded" />
      </div>
    </div>
    );
  }

  const showContentVideo = (doc) => {
   return (
   <div className="flex justify-center">
    <div className="rounded-lg shadow p-2 border" style={{ width: '70%' }}>
      <span className="capitalize font-semibold ml-1 text-gray-500 italic">{doc.title}</span>
      <hr className="mb-2" />
      <div className="flex justify-center">
        <iframe width="100%" height="365" src={doc.file} title="Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
    </div>
  </div>
  );
  }

  const showContentEnlace = (doc,type) => {
    let ruta =  state.rutaStorage + doc.file;
    if((type==='ENLACE')) ruta = doc.file;
    return (
      <div>
        <span className="text-cbm mr-2" style={{ 'fontSize': '6pt' }}>■</span>
        <a className="hover:text-blue-600 w-100 capitalize no-underline hover:underline decoration-sky-500" target='_blank' href={ruta} rel="noreferrer">
          {doc.title}
        </a>
     </div>
    );
  }

  const showContentNoRegistros = () => {
    return (
      <div className="w-full bg-gray-100 text-center rounded py-1">
        <span className="text-lg font-medium">Sin registros.</span>
      </div>
    );
  }

  const showContentExcel = (doc,id) => {
     return (
     <div className="align-middle">
      {!doc.datos_adicionales ? <span className="text-cbm mr-2" style={{ 'fontSize': '6pt' }}>■</span> : ''}
       {
        doc.datos_adicionales ? 
        openItems.includes(id) ? (
          <FontAwesomeIcon icon={faMinus} className="m-0" style={{ 'cursor': 'pointer', 'marginRight':'3pt' }}  onClick={() => toggleItem(id)} />
        ) : (
          <FontAwesomeIcon icon={faPlus} className="m-0" style={{ 'cursor': 'pointer','marginRight':'3pt' }}  onClick={() => toggleItem(id)} />
        )
         : ''
       }
      <span className="hover:text-blue-600 w-100 capitalize no-underline hover:underline decoration-sky-500" style={{ "cursor": "pointer" }} onClick={() => updateModalExcel(doc.file)}>
        {doc.title}
      </span>
      {
        doc.datos_adicionales ? 
        <div key={id}>
            {/* <div className={`${openItems.includes(id) ? "block mb-3 mt-1" : "hidden"} bg-white pt-1 p-2 ms-7 border rounded`}> */}
          <div className={`grid transition-all duration-500 ease-in-out ms-7 overflow-hidden ${openItems.includes(id)? "grid-rows-[1fr] opacity-100 mb-3 mt-1": "grid-rows-[0fr] opacity-0 p-0 max-h-0"}`}>
            
              <ul>
                {
                   JSON.parse(doc.datos_adicionales).map((_doc, i) =>
                    <div key={i}>
                      {
                        doc.type_file === 'IMAGE' ?
                        showContentImage(_doc)
                          : (
                            doc.type_file === 'VIDEO' ?
                            showContentVideo(_doc)
                              : (
                                doc.type_file === 'PDF' ?
                                showContentEnlace(_doc,'PDF')
                                  : (
                                    doc.type_file === 'ENLACE' ?
                                    showContentEnlace(_doc,'ENLACE')
                                      :
                                      (
                                        doc.type_file === 'EXCEL' ?
                                        showContentExcel(_doc,(i))
                                          : ''
                                      )
                                  )          
                              )
                          )
                      }
                    </div>
                  )
                }
              </ul>
            </div>
        </div>
         : ''
       }
    
    </div>
     );
  }
  /* Funciones para mostrar contenido */

  return (
    <Layout>
      <div className="h-full">
        <div className="w-full h-96">
          <img className="h-full w-full" src="/img/transparencia2024.jpg" alt="" style={{ "height": "100%", "objectFit": "cover", "objectPosition": "50% 40%" }} />
        </div>
        <div className="w-full flex justify-center relative">
          <div className="w-3/4 -top-12 bg-black shadow shadow-gray-500/40 opacity-[.85] absolute py-5 rounded-md text-center text-4xl text-white font-bold">
            Transparencia
          </div>
        </div>
        {
          state.loading ?
            <div className="flex justify-center items-center mt-20">
              <FontAwesomeIcon icon={faSpinner} className="mr-1 text-gray-500" pulse size="2x" />
            </div>
            :
            <div className="mt-20 px-8 pb-[80pt]">
              {
                state.anioActual !== null ?
                  detalleAnios()
                  :
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {anioItems}
                  </div>
              }
            </div>
        }
      </div>
      <ModalExcel></ModalExcel>
    </Layout>
  );
};

export default Transparencia;
