import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faClipboardCheck, faCreditCard, faEnvelope, faGraduationCap, faLock, faSackDollar } from '@fortawesome/free-solid-svg-icons'

import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Header = () => {

  const [categorias, setCategorias] = useState([]);

  const readCategorias = async () => {
    // setState({ ...state, loading: true });
    const url = "https://bp.bomberosmanta.gob.ec/api/categorias-news/index";
    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      }
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      // setState({ ...state, loading: false });
      setCategorias(content);
    } else {
      setCategorias([]);
      // setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    // readCategorias()
  }, [])

  const noticiasCategorias = () => {
    const array = [];
    for (let i = 0; i < categorias.length; i = i + 1) {
      const categoria = categorias[i];
      array.push(
        <Link to={"/noticias/" + categoria.id} key={'enlace_' + i}>{categoria.name}</Link>
      );
    }
    return array;
  }

  return (
    <div className="py-2 bg-gray-800 px-4">
      <div className="flex justify-end font-semibold">
        <ul className="flex text-white text-sm gap-2 ">
        <li className="hover:text-gray-300 mr-2">
            <a href="https://portal.bomberosmanta.gob.ec/" target="_blank" rel="noreferrer">
              <span title="Permiso Funcionamiento">
                <FontAwesomeIcon icon={faClipboardCheck} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Permiso de funcionamiento</span>
            </a>
          </li>
          <li className="hover:text-gray-300 mr-2">
            <a href="https://portal.bomberosmanta.gob.ec/" target="_blank" rel="noreferrer">
              <span title="Contribucion Predial">
                <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Pago en línea</span>
            </a>
          </li>
          <li className="hover:text-gray-300 mr-2">
            <a href="https://portal.bomberosmanta.gob.ec/ciudadano/consulta" target="_blank" rel="noreferrer">
              <span title="Deudas">
                <FontAwesomeIcon icon={faSackDollar} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Consulta Deudas</span>
            </a>
          </li>
          <li className="hover:text-gray-300 mr-2">
            <a href="https://capacitacion.bomberosmanta.gob.ec/" target="_blank" rel="noreferrer">
              <span title="Capacitacion">
                <FontAwesomeIcon icon={faGraduationCap} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Aula Virtual</span>
            </a>
          </li>
          <li className="hover:text-gray-300 mr-2">
            <a href="https://mail.bomberosmanta.gob.ec/" target="_blank" rel="noreferrer">
              <span title="Correo">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Correo Electrónico</span>
            </a>
          </li>
          <li className="hover:text-gray-300 mr-2">
            <a href="https://dashboard.bomberosmanta.gob.ec/" target="_blank" rel="noreferrer">
              <span title="Administracion">
                <FontAwesomeIcon icon={faLock} className="mr-1" />
              </span>
              <span className="hidden sm:inline-block md:inline-block lg:inline-block xl:inline-block 2xl:inline-block">Acceso</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="w-70">
        <a href="/" className="flex items-center gap-2 text text-white">
          <img
            src="/img/logos/blanco.svg"
            width={75}
            alt="favicon"
            className="navL-img"
          />
          <div className="ml-1">
            <img
              src="/img/logos/letras.svg"
              width={130}
              alt="favicon"
              className="navL-img"
            />
          </div>
          {/* <div className="flex flex-col">
            <strong>CUERPO DE BOMBEROS</strong>
            <span className="flex justify-end text-xs">
              <img
                src="./img/logos/manta.png"
                width={70}
                alt="favicon"
                className="navL-img"
              />
            </span>
          </div> */}
        </a>
      </div>

      <div className="flex justify-end">
        <ul className="flex text-gray-300 text-sm gap-2 font-light dropdown-menu">
          <li className="hover:text-white">
            <Link to="/">Inicio</Link>
          </li>
          <li className="hover:text-white dropdown">
            <Link className="dropbtn">
              Intitución
            </Link>
            <div className="dropdown-content text-white font-bold" style={{"zIndex":"2"}}>
              <Link to="/historia">Historia</Link>
              <Link to="/simbolos">Símbolos</Link>
              <Link to="/valores">Valores Institucionales</Link>
              <Link to="/quienes-somos">¿Quiénes somos?</Link>
            </div>
          </li>

          <li className="hover:text-white dropdown">
            <Link className="dropbtn">
              Formularios
            </Link>
            <div className="dropdown-content text-white font-bold" style={{"zIndex":"2"}}>
              {/* <Link to="/registro-brigadistas">Bomberos Brigadistas</Link>          */}
              {/* <Link to="/registro-voluntarios">Voluntarios</Link> */}
              <Link to="/registro-bomberosjr">Formulario de Inscripción Bomberos Jr</Link>
              <Link to="/sugerencias">
                Formulario de sugerencias denuncias y reconocimientos.
              </Link>
            </div>
          </li>
          <li className="hover:text-white dropdown">
            <Link>Ley de Transparencia</Link>
            <div className="dropdown-content text-white font-bold" style={{"zIndex":"2"}}>
              <Link to="/transparencia">Transparencia</Link>
              <Link to="/rendicion_cuentas">Rendición de Cuentas</Link>
              <Link to="/base_legal">Base Legal</Link>
              <Link to="/repositorio_digital">Repositorio Digital</Link>
            </div>
          </li>
          <li className="hover:text-white dropdown">
            <Link to="/noticias">Noticias</Link>
            {/* <div className="dropdown-content text-white font-bold" style={{"zIndex":"2"}}>
              {noticiasCategorias()}
            </div> */}
          </li>
          {/* <li className="hover:text-white dropdown">
            <Link>Prevención</Link>
            <div className="dropdown-content text-white font-bold">
              <Link to="/amenazas-naturales">Amenazas Naturales</Link>
            </div>
          </li> */}
          <li className="hover:text-white">
            <Link to="/contactanos">Contáctanos</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
